.react-tabs {
    -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
    border-top: 1px solid var(--tab-border);
    margin: 0 0 10px;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

.react-tabs__tab--selected {
    background-color: var(--tab-background);

}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}